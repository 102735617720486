
.btn-site {
    background-color: #002447 !important;
}

.btn-site2 {
    background-color: #003366 !important;
}

.btn-site3 {
    background-color: #4e576b !important;
}

@media screen and (min-width: 600px) {
    .hideOnMobile {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 10px auto 5px 20px;
        width: 28%;
        display: none;
    }
}
